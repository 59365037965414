import { SetMobileMenuOpen } from 'models/SetMobileMenuOpen';
import React, { RefObject, useRef } from 'react';
import { useModal } from 'context/modal';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import {
  CompanyHeader as CompanyHeaderStyled,
  CompanyLogoLinkWrapper,
  CompanyName,
  HeaderContent,
  HeaderContentWrapper,
  HeaderLogoAndContent,
  RatingInPeriod,
  ReviewsSubheading,
  ShortenedCompanyName,
  SummaryInfo
} from 'components/CompanyHeader/CompanyHeader.styles';
import { ANIMATED_COMPANY_HEADER_ID } from 'services/page-section-ids';
import {
  PROFILE_HEADER_AREA,
  PROFILE_TOOLTIP
} from 'services/data-test-attributes';
import {
  NavigationV2,
  Tooltip
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import columnSizes from 'styles/column-sizes.variables';
import { addHttpPrefix } from 'helpers/addHttpPrefix';
import CompanyLogo from 'components/CompanyLogo/CompanyLogo';
import { RatingsArea } from 'components/CompanyHeader/RatingsArea/RatingsArea';
import { SubmitReview } from 'components/SubmitReview/SubmitReview';
import { PageNavigation } from 'components/CompanyHeader/PageNavigation/PageNavigation';
import dynamic from 'next/dynamic';
import { MemberProfileBadge } from 'components/Illustrations/MemberProfileBadge/MemberProfileBadge';
import { FreeAccountProfileBadge } from 'components/Illustrations/FreeAccountProfileBadge/FreeAccountProfileBadge';
import { TargetMarket } from 'profile-api';
import { Environment } from 'models/Environment';

const VerificationTooltipContent = dynamic(
  () =>
    import(
      'components/CompanyHeader/VerificationTooltipContent/VerificationTooltipContent'
    ),
  { ssr: false }
);

const SwitchSSO = dynamic(() => import('components/SwitchSSO/SwitchSSO'), {
  ssr: false
});

export default function CompanyHeaderContent({
  id,
  translations,
  mobileMenuOpen,
  setMobileMenuOpen,
  pageHeaderRef,
  scrollingAnimationEnabled,
  classNames,
  hasExecutedInitialAnimation
}: {
  id: string;
  translations: any;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: SetMobileMenuOpen;
  pageHeaderRef?: RefObject<HTMLDivElement>;
  scrollingAnimationEnabled?: boolean;
  classNames?: string[];
  hasExecutedInitialAnimation?: boolean;
}) {
  const { isModalOpen } = useModal();
  const { formatMessage, formatNumber } = useIntl();
  const {
    profile,
    isMemberProfile,
    isNonMemberProfile,
    isFreeAccount,
    isOpenProfile,
    environment
  } = useStore();
  const refForTooltip = useRef<HTMLSpanElement>(null);

  const summaryIsVisible = reviewsStateIs(
    profile,
    ReviewsVisibilityState.SummaryIsVisible
  );
  const hasNoReviews = !summaryIsVisible;

  return (
    <CompanyHeaderStyled
      id={id}
      ref={id === ANIMATED_COMPANY_HEADER_ID ? pageHeaderRef : null}
      className={classNames && classNames.join(' ')}
      data-test={PROFILE_HEADER_AREA}
      hasNoReviews={!summaryIsVisible}
      isModalOpen={isModalOpen}
      hasExecutedInitialAnimation={hasExecutedInitialAnimation}
    >
      <NavigationV2
        hasSearchBar
        searchBarVisibleFromPx={-1}
        translations={translations}
        activeFilters={{}}
        isStaticPosition={true}
        mobileMenuHandler={{
          mobileMenuOpen: mobileMenuOpen,
          setMobileMenuOpen: setMobileMenuOpen
        }}
        searchPlaceholder={formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
        customHeaderWidths={columnSizes}
        switchSlot={
          profile.targetMarket &&
          (profile.targetMarket === TargetMarket.Gbr ||
            profile.targetMarket === TargetMarket.Deu ||
            (profile.targetMarket === TargetMarket.Aut &&
              environment !== Environment.production)) ? (
            <SwitchSSO />
          ) : null
        }
      />
      <HeaderLogoAndContent
        isMemberProfile={isMemberProfile}
        hasNoReviews={!summaryIsVisible}
        className={
          scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
        }
      >
        {profile.url && profile.logoUrl && (
          <CompanyLogoLinkWrapper
            target="_blank"
            href={addHttpPrefix(profile.url)}
            rel="noreferrer"
            className={
              scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
            }
          >
            <CompanyLogo />
          </CompanyLogoLinkWrapper>
        )}
        <HeaderContentWrapper
          className={
            scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
          }
        >
          <HeaderContent>
            <div>
              <CompanyName
                hasNoReviews={hasNoReviews}
                as={scrollingAnimationEnabled ? 'div' : 'h1'}
                lookAlike={'h1'}
                className={
                  scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
                }
              >
                <>
                  <ShortenedCompanyName
                    className={
                      scrollingAnimationEnabled
                        ? 'scrolling-animation-enabled'
                        : ''
                    }
                  >
                    <span>{profile.name}</span>
                    {!isNonMemberProfile && (
                      <Tooltip
                        childrenRef={refForTooltip}
                        uniqueId={'profile-type'}
                        backgroundColor={'#1D2939'}
                        content={<VerificationTooltipContent />}
                        placement={'bottom'}
                        maxWidthMobile={'218px'}
                        maxWidthDesktop={'236px'}
                      >
                        <span ref={refForTooltip}>
                          {isMemberProfile && (
                            <MemberProfileBadge data-test={PROFILE_TOOLTIP} />
                          )}
                          {isFreeAccount && (
                            <FreeAccountProfileBadge
                              data-test={PROFILE_TOOLTIP}
                            />
                          )}
                        </span>
                      </Tooltip>
                    )}
                  </ShortenedCompanyName>
                </>
                <ReviewsSubheading
                  scrollingAnimationEnabled={scrollingAnimationEnabled}
                >
                  {formatMessage({
                    id: 'COMPANYPROFILES.PAGE_NAVIGATION.REVIEWS'
                  })}
                  {': '}
                </ReviewsSubheading>
              </CompanyName>
              <SummaryInfo
                isMemberProfile={isMemberProfile}
                hasNoReviews={hasNoReviews}
                as="h2"
                className={
                  scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
                }
              >
                {summaryIsVisible ? (
                  <>
                    {profile.reviewStatistic?.allTimeReviewCount && (
                      <RatingInPeriod>
                        <span suppressHydrationWarning>
                          {formatMessage(
                            {
                              id: 'COMPANYPROFILES.RATINGS.SUMMARY.YEARLY_RATING_COUNT.PART_1'
                            },
                            {
                              count: formatNumber(
                                profile.reviewStatistic?.yearlyReviewCount
                              )
                            }
                          )}
                          &nbsp;
                        </span>
                        <span>
                          {formatMessage({
                            id: 'COMPANYPROFILES.RATINGS.SUMMARY.YEARLY_RATING_COUNT.PART_2'
                          })}
                        </span>
                      </RatingInPeriod>
                    )}
                    <RatingInPeriod>
                      <span>
                        {formatMessage(
                          {
                            id: 'COMPANYPROFILES.RATINGS.SUMMARY.TOTAL_RATING_COUNT.PART_1'
                          },
                          {
                            count: formatNumber(
                              profile.reviewStatistic?.allTimeReviewCount
                            )
                          }
                        )}
                        &nbsp;
                      </span>
                      <span>
                        {formatMessage({
                          id: 'COMPANYPROFILES.RATINGS.SUMMARY.TOTAL_RATING_COUNT.PART_2'
                        })}{' '}
                      </span>
                      <span suppressHydrationWarning>
                        &nbsp;
                        {formatMessage(
                          {
                            id: 'COMPANYPROFILES.RATINGS.SUMMARY.TOTAL_RATING_COUNT.PART_3'
                          },
                          {
                            count: formatNumber(
                              profile.reviewStatistic?.allTimeReviewCount
                            )
                          }
                        )}
                      </span>
                    </RatingInPeriod>
                  </>
                ) : (
                  <ReviewsSubheading>
                    {formatMessage({
                      id: 'COMPANYPROFILES.HEADER.NO_REVIEWS.LABEL'
                    })}
                  </ReviewsSubheading>
                )}
              </SummaryInfo>
            </div>
            <RatingsArea
              summaryIsVisible={summaryIsVisible}
              scrollingAnimationEnabled={scrollingAnimationEnabled}
            />
          </HeaderContent>
        </HeaderContentWrapper>
        {isOpenProfile && (
          <SubmitReview
            scrollingAnimationEnabled={scrollingAnimationEnabled}
            hasNoReviews={!summaryIsVisible}
          />
        )}
      </HeaderLogoAndContent>
      {!hasNoReviews && (
        <PageNavigation
          id={id}
          scrollingAnimationEnabled={scrollingAnimationEnabled}
          pageHeaderRef={pageHeaderRef}
        />
      )}
    </CompanyHeaderStyled>
  );
}
